<template>
	<S2SForm :title="displayText ? 'Email Activity' : `Email Activity for ${email} (last 7days)`">
		<div v-if="displayText">
			<v-layout row wrap justify-end mr-4 my-2>
				<v-flex xs6 md6>
					<v-text-field
						v-model="email"
						append-outer-icon="search"
						clearable
						label="Search by email"
						type="text"
						@click:append-outer="fetchActivity"
						@keypress.enter="fetchActivity"
					></v-text-field>
				</v-flex>
			</v-layout>
		</div>

		<div v-else>
			<v-layout row wrap justify-end mr-4 my-2>
				<v-flex xs6 md6>
					<v-btn class="ml-3" color="accent" @click="reset()">New search</v-btn>
				</v-flex>
				<v-flex md6>
					<v-layout row wrap justify-end>
						<v-flex sm11>
							<v-text-field v-model="filterVal" label="Filter"></v-text-field>
						</v-flex>
						<v-flex sm1 align-self-center class="text-center">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on" @click="fetchActivity"><v-icon>refresh</v-icon></v-btn>
								</template>
								<span>Refresh Activity</span>
							</v-tooltip>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<v-data-table
				:headers="activityHeaders"
				:items="allActivity"
				class="elevation-1"
				item-key="msg_id"
				:search="filterVal"
				:loading="searchUserStatus.loading"
			>
				<template v-slot:item.last_event_time="{ item }">
					{{ formatDate(item.last_event_time) }}
				</template>
				<template v-slot:item.status="{ item }">
					<span :class="getStatusColour(item.status)">
						{{ ucFirst(item.status) }}
					</span>
				</template>
			</v-data-table>
		</div>

		<v-divider v-if="displayBounces" class="mt-5 mb-5"></v-divider>

		<v-card v-if="displayBounces">
			<v-card-title>EMAIL BOUNCES</v-card-title>
			<v-data-table :headers="bounceHeaders" :items="allBounces" class="elevation-1" :search="filterVal">
				<template v-slot:item.created="{ item }">
					{{ getDate(item.created) }}
				</template>
			</v-data-table>
		</v-card>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "EmailActivity",

	props: {
		defaultEmail: {
			type: String,
			default: ""
		}
	},

	computed: {
		emailActivity: function() {
			return this.$store.state.user.emailActivity;
		},
		emailBounces: function() {
			return this.$store.state.user.emailBounces;
		},
		searchUserStatus: function() {
			return this.$store.state.user.searchUserStatus;
		}
	},

	watch: {
		emailActivity() {
			this.allActivity = this.emailActivity;
		},
		emailBounces() {
			this.allBounces = this.emailBounces;
		}
	},

	data: function() {
		return {
			allActivity: [],
			allBounces: [],
			email: "",
			filterVal: "",

			displayText: true,
			displayBounces: false,

			activityHeaders: [
				{
					text: "Date",
					value: "last_event_time"
				},
				{
					text: "Subject",
					value: "subject"
				},
				{
					text: "Status",
					value: "status"
				},
				{
					text: "Clicks",
					value: "clicks_count"
				},
				{
					text: "Opens",
					value: "opens_count"
				}
			],

			bounceHeaders: [
				{
					text: "Created",
					value: "created"
				},
				{
					text: "Email",
					value: "email"
				},
				{
					text: "Reason",
					value: "reason"
				}
			]
		};
	},

	mounted: function() {
		this.email = this.$props.defaultEmail;
		this.fetchActivity(this.email);
	},

	methods: {
		async fetchActivity() {
			if (this.email != "") {
				this.displayText = false;
				let success = await this.$store.dispatch("user/fetchEmailActivity", this.email);
				this.fetchBounces();
			}
		},
		async fetchBounces() {
			if (this.email != "") {
				await this.$store.dispatch("user/fetchEmailBounces", this.email);
				if (this.allBounces.length) this.displayBounces = true;
			}
		},
		getStatusColour(status) {
			let colour = "";
			switch (status) {
				case "delivered":
					colour = "green--text";
					break;
			}

			return colour;
		},
		reset() {
			this.displayText = true;
			this.displayBounces = false;
			this.email = "";
			this.filterVal = "";
			this.allActivity = [];
			this.allBounces = [];
		},
		ucFirst(status) {
			let str = status.replace(/_/g, " ");
			return str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
				return letter.toUpperCase();
			});
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		getDate(val) {
			return new Date(val * 1000).toLocaleDateString("en-CA");
		}
	}
});
</script>
