var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":_vm.displayText ? 'Email Activity' : ("Email Activity for " + _vm.email + " (last 7days)")}},[(_vm.displayText)?_c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":"","mr-4":"","my-2":""}},[_c('v-flex',{attrs:{"xs6":"","md6":""}},[_c('v-text-field',{attrs:{"append-outer-icon":"search","clearable":"","label":"Search by email","type":"text"},on:{"click:append-outer":_vm.fetchActivity,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchActivity.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1):_c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":"","mr-4":"","my-2":""}},[_c('v-flex',{attrs:{"xs6":"","md6":""}},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"accent"},on:{"click":function($event){return _vm.reset()}}},[_vm._v("New search")])],1),_c('v-flex',{attrs:{"md6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"sm11":""}},[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filterVal),callback:function ($$v) {_vm.filterVal=$$v},expression:"filterVal"}})],1),_c('v-flex',{staticClass:"text-center",attrs:{"sm1":"","align-self-center":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.fetchActivity}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Activity")])])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.activityHeaders,"items":_vm.allActivity,"item-key":"msg_id","search":_vm.filterVal,"loading":_vm.searchUserStatus.loading},scopedSlots:_vm._u([{key:"item.last_event_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.last_event_time))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getStatusColour(item.status)},[_vm._v(" "+_vm._s(_vm.ucFirst(item.status))+" ")])]}}])})],1),(_vm.displayBounces)?_c('v-divider',{staticClass:"mt-5 mb-5"}):_vm._e(),(_vm.displayBounces)?_c('v-card',[_c('v-card-title',[_vm._v("EMAIL BOUNCES")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bounceHeaders,"items":_vm.allBounces,"search":_vm.filterVal},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.created))+" ")]}}],null,false,1109058092)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }